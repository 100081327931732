import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import styles from "../../stylesheets/customToast.module.css";
import { Icon } from "../Icon";
import React from "react";

export const successModal = (message: any, open: boolean, handleClose: any) => {
  const style = {
    position: "absolute" as "absolute",
    display: "flex",
    flexDirection: "column",
    font: "Plus Jakarta Sans",
    alignItems: "center",
    justifyContent: "space-around",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        role="dialog"
        aria-labelledby="child-modal-title"
      >
        <Box sx={{ ...style, width: "20rem", height: "18rem" }}>
          <div className={styles.errorheader}>
            <></>
            <Button
              onClick={handleClose}
              className={styles.cancel_icon_button}
              aria-label="Close"
            >
              <Icon name={`Close`} />
            </Button>
          </div>
          <h2
            id="child-modal-title"
            style={{
              fontSize: "2.4rem",
              marginTop: "-2rem",
              fontFamily: "Plus Jakarta Sans",
            }}
          >
            Success
          </h2>
          <p
            id="child-modal-description"
            style={{
              fontSize: "1rem",
              marginTop: "-2rem",
              fontFamily: "Plus Jakarta Sans",
              color: "#065F46",
            }}
          >
            {message}
          </p>
          <Button onClick={handleClose} className={styles.buttonStyle}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

// export default {errorModal, successModal};

// export default

export const errorToast = (message: any) => {
  return toast.error(message, {
    role: "alert",
    position: "top-center",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const successToast = (message: any, time: number) => {
  return toast.success(message, {
    position: "top-center",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
