// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_main_container__lJU20 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-right: 4%;
}

.Dashboard_text_container__uxd1p {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  background-color: var(--backgroundSecondary);
  border-radius: 1rem;
  padding: 1.5rem 1.875rem;
}

.Dashboard_text_container__uxd1p h2 {
  font-size: 1.25rem;
  line-height: 1;
}

.Dashboard_text_container__uxd1p p {
  font-weight: 500;
  font-size: 0.875rem;
}

.Dashboard_text_container__uxd1p button {
  border: none;
  padding: 0.5rem 1rem;
}

.Dashboard_text_container__uxd1p button svg {
  --add-icon-size: 1rem;
  width: var(--add-icon-size);
  height: var(--add-icon-size);
}

.Dashboard_table_container__W1r0C {
  border-radius: 0.875rem;
  background-color: var(--backgroundSecondary);
  padding: 1rem 1.25rem;
}

.Dashboard_table_container__W1r0C strong[data-status] {
  background-color: #d1fae5;
  color: #065f46;
}

.Dashboard_table_container__W1r0C strong[data-status="expired"] {
  background-color: #fee2e2;
  color: #991b1b;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/Dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,4CAA4C;EAC5C,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;EACvB,4CAA4C;EAC5C,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".main_container {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n  padding-right: 4%;\n}\n\n.text_container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 0.625rem;\n  background-color: var(--backgroundSecondary);\n  border-radius: 1rem;\n  padding: 1.5rem 1.875rem;\n}\n\n.text_container h2 {\n  font-size: 1.25rem;\n  line-height: 1;\n}\n\n.text_container p {\n  font-weight: 500;\n  font-size: 0.875rem;\n}\n\n.text_container button {\n  border: none;\n  padding: 0.5rem 1rem;\n}\n\n.text_container button svg {\n  --add-icon-size: 1rem;\n  width: var(--add-icon-size);\n  height: var(--add-icon-size);\n}\n\n.table_container {\n  border-radius: 0.875rem;\n  background-color: var(--backgroundSecondary);\n  padding: 1rem 1.25rem;\n}\n\n.table_container strong[data-status] {\n  background-color: #d1fae5;\n  color: #065f46;\n}\n\n.table_container strong[data-status=\"expired\"] {\n  background-color: #fee2e2;\n  color: #991b1b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_container": `Dashboard_main_container__lJU20`,
	"text_container": `Dashboard_text_container__uxd1p`,
	"table_container": `Dashboard_table_container__W1r0C`
};
export default ___CSS_LOADER_EXPORT___;
