import { Link } from "react-router-dom";
import { Icon } from "../../components/Icon";

interface BreadcrumbProps {
  Links: {
    label: string;
    to: string;
  }[];
  stylesClass: string;
}

export const Breadcrumb = ({ Links, stylesClass }: BreadcrumbProps) => {
  return (
    <nav aria-label="Breadcrumb" className={stylesClass}>
      <ol>
        {Links.map((link, index) => {
          return (
            <li key={index}>
              {index !== Links.length - 1 ? (
                <>
                  <Link to={link.to}>{link.label}</Link>
                  <Icon name="ChevronRight" />
                </>
              ) : (
                <Link to={link.to} aria-current="page">
                  {link.label}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
