import {
  CSSProperties,
  ReactNode,
  MouseEvent,
  useId,
  useRef,
  useState,
  useEffect,
} from "react";
import { noop } from "../../utils/general";
import { Icon } from "../Icon";
import "./Table.module.css";

type sortOrderType = "ascending" | "descending";

interface TableProps {
  title: string;
  showTitle?: boolean;
  headers: {
    label: string;
    sortBy: boolean;
  }[];
  initialSortHeader: string;
  fetchTableData: typeof noop;
  tableDataLength: number;
  renderTableBody: () => ReactNode;
}

export const Table = ({
  title,
  showTitle = true,
  headers,
  initialSortHeader,
  fetchTableData,
  tableDataLength,
  renderTableBody,
}: TableProps) => {
  const id = useId();
  const notifierRef = useRef<HTMLDivElement>(null!);
  const [sortHeader, setSortHeader] = useState<string>(initialSortHeader);
  const [sortOrder, setSortOrder] = useState<sortOrderType>("descending");

  const isTableWithData = tableDataLength > 0;

  const columns2SortBy = headers
    .filter((header) => header.sortBy)
    .map((header) => header.label)
    .join(", ")
    .replace(/,\s((?:\w+\s)*\w+)$/, " and $1");

  const sortNotification4ScreenReaders = (
    header: string,
    order: sortOrderType
  ) => {
    notifierRef.current.textContent = `Sorting by ${header} in ${order} order`;
    // clear after notifying
    setTimeout(() => {
      notifierRef.current.textContent = "";
    }, 1000);
  };

  const handleTableSort = (e: MouseEvent<HTMLTableCellElement>) => {
    if (tableDataLength <= 1) return; // can't sort table with no or one row of data
    const [, activatedHeader] = e.currentTarget.id.split("-");
    const activatedOrder =
      activatedHeader !== sortHeader
        ? "descending"
        : sortOrder === "descending"
        ? "ascending"
        : "descending";
    sortNotification4ScreenReaders(activatedHeader, activatedOrder);
    fetchTableData(activatedHeader, activatedOrder);
    setSortHeader(activatedHeader);
    setSortOrder(activatedOrder);
  };

  useEffect(() => {
    fetchTableData(sortHeader, sortOrder);
  }, []);

  return (
    <>
      <table style={{ "--table-columns": headers.length } as CSSProperties}>
        <caption {...(!showTitle && { className: "visually-hidden" })}>
          <span>{title}</span>{" "}
          <span {...(showTitle && { className: "visually-hidden" })}>
            {`Use ${columns2SortBy} buttons to sort table data`}
          </span>
        </caption>
        <thead>
          <tr>
            {headers.map((header) => {
              return header.sortBy ? (
                <th
                  key={header.label}
                  id={`${id}-${header.label}`}
                  scope="col"
                  {...(isTableWithData &&
                    header.label === sortHeader && { "aria-sort": sortOrder })}
                  onClick={handleTableSort}
                >
                  <button>
                    <span>{header.label}</span>
                    <span>
                      <Icon name="ChevronUp" />
                      <Icon name="ChevronDown" />
                    </span>
                  </button>
                </th>
              ) : (
                <th key={header.label} id={`${id}-${header.label}`} scope="col">
                  {header.label}
                </th>
              );
            })}
          </tr>
        </thead>
        {isTableWithData ? renderTableBody() : null}
      </table>
      <div role="alert" className="visually-hidden" ref={notifierRef}></div>
    </>
  );
};
