// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_profile_container__\\+vOnR {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
}

.Profile_heading__11NjB {
    font-family: 'Plus Jakarta Sans', sans-serif;
    height: 7%;
    align-self: flex-start;
    margin-left: 3.125rem; 
}

.Profile_frame__mUh4R {
    width: 100%;
    height: 70%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Profile_options_wrapper__YEV8G {
    margin-top: 1.25rem; 
    margin-left: 1.875rem;
    height: 10%;
    display: flex;
    border-bottom: 1px solid #F5F5F7;
}

.Profile_option_span__7PB74 {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    font-size: 1.125rem; 
    padding: 1.25rem; 
    transition-duration: 100ms;
    cursor: pointer;
}

.Profile_active_option__Azsa1 {
    border-bottom: 0.1875rem solid #5F2498;
}

.Profile_dim_option__uM2az {
    font-weight: 100;
    color: #545775;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profile/Profile.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,4CAA4C;IAC5C,UAAU;IACV,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,WAAW;IACX,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,4CAA4C;IAC5C,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".profile_container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    background-color: white;\n    flex-direction: column;\n    align-items: center;\n}\n\n.heading {\n    font-family: 'Plus Jakarta Sans', sans-serif;\n    height: 7%;\n    align-self: flex-start;\n    margin-left: 3.125rem; \n}\n\n.frame {\n    width: 100%;\n    height: 70%;\n    background-color: white;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.options_wrapper {\n    margin-top: 1.25rem; \n    margin-left: 1.875rem;\n    height: 10%;\n    display: flex;\n    border-bottom: 1px solid #F5F5F7;\n}\n\n.option_span {\n    font-family: 'Plus Jakarta Sans', sans-serif;\n    font-weight: 500;\n    font-size: 1.125rem; \n    padding: 1.25rem; \n    transition-duration: 100ms;\n    cursor: pointer;\n}\n\n.active_option {\n    border-bottom: 0.1875rem solid #5F2498;\n}\n\n.dim_option {\n    font-weight: 100;\n    color: #545775;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile_container": `Profile_profile_container__+vOnR`,
	"heading": `Profile_heading__11NjB`,
	"frame": `Profile_frame__mUh4R`,
	"options_wrapper": `Profile_options_wrapper__YEV8G`,
	"option_span": `Profile_option_span__7PB74`,
	"active_option": `Profile_active_option__Azsa1`,
	"dim_option": `Profile_dim_option__uM2az`
};
export default ___CSS_LOADER_EXPORT___;
