import { useState, useRef, useEffect, ChangeEvent, FormEvent } from "react";
import { UPDATE_KEY_ENDPOINT } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { TextField } from "../../components/TextField";
import { Icon } from "../../components/Icon";

interface EditKeyDetailProps {
  apiKey: string;
  detailLabel: string;
  detailValue: string;
  formStylesClass: string;
  textFieldStylesClass: string;
  EditCtrlStylesClass: string;
}

export const EditKeyDetail = ({
  apiKey,
  detailLabel,
  detailValue,
  formStylesClass,
  textFieldStylesClass,
  EditCtrlStylesClass,
}: EditKeyDetailProps) => {
  const getCapitalizedDetailLabel = () =>
    detailLabel
      .split("_")
      .map((substr) => substr[0].toUpperCase() + substr.slice(1))
      .join(" ");
  const [currDetailValue, setCurrDetailValue] = useState(detailValue); // holds the most recent detail value
  const [formData, setFormData] = useState({
    [detailLabel]: currDetailValue,
  });
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData({ [name]: value });
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const [showEditForm, setShowEditForm] = useState(false);
  const renderEditForm = () => setShowEditForm(true);
  const cancelDetailUpdate = () => {
    setShowEditForm(false);
    setFormData({ [detailLabel]: currDetailValue }); // reset to pre-edit value
  };
  const { isLoading, makeRequest } = useCustomAxios();
  const handleDetailUpdate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowEditForm(false);
    makeRequest(
      [
        {
          url: UPDATE_KEY_ENDPOINT,
          method: "post",
          data: {
            api_key: apiKey,
            ...formData,
          },
        },
      ],
      () => {
        setCurrDetailValue(formData[detailLabel]); // updates the UI
      },
      (error) => console.error(error) // TODO: refactor error handling
    );
  };

  useEffect(() => {
    if (showEditForm) inputRef.current?.focus();
  }, [showEditForm]);

  return isLoading ? (
    // TODO: refactor to HTML component
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
      <Typography role="alert">{`Updating ${getCapitalizedDetailLabel()}...`}</Typography>
    </Backdrop>
  ) : showEditForm ? (
    <form noValidate className={formStylesClass} onSubmit={handleDetailUpdate}>
      <TextField
        id={detailLabel}
        label={getCapitalizedDetailLabel()}
        name={detailLabel}
        value={formData[detailLabel]}
        setValue={handleInputChange}
        stylesClass={textFieldStylesClass}
        ref={inputRef}
      />
      <button type="submit">
        <Icon name="Check" hidden4Sr={false} accessible_name="Save" />
      </button>
      <button type="button" onClick={cancelDetailUpdate}>
        <Icon name="Close" hidden4Sr={false} accessible_name="Cancel" />
      </button>
    </form>
  ) : currDetailValue ? (
    <p className={EditCtrlStylesClass}>
      <span>{currDetailValue[0].toUpperCase() + currDetailValue.slice(1)}</span>
      <button type="button" onClick={renderEditForm}>
        <Icon
          name="Edit"
          hidden4Sr={false}
          accessible_name={`Edit ${detailLabel.split("_").join(" ")}`}
        />
      </button>
    </p>
  ) : (
    <button
      type="button"
      onClick={renderEditForm}
      className={EditCtrlStylesClass}
    >
      <span>{`Edit ${detailLabel.split("_").join(" ")}`}</span>
      <Icon name="Edit" />
    </button>
  );
};
