import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import { SESSION_LOGIN_ENDPOINT } from "../api/endpoints";
import { useCustomAxios } from "../api/axios";
import { noop } from "../utils/general";

type UserDataType = {
  user_type: string;
  first_name: string;
  last_name: string;
  email: string;
  created_at: string;
} | null;

interface AuthContextType {
  isAuthenticated: boolean;
  userData: UserDataType;
  updateUserType: (newUserType: string) => void;
  login: (data: UserDataType) => void;
  logout: typeof noop;
}

const AuthContext = createContext({} as AuthContextType);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    () => !!localStorage.getItem("isLoggedIn")
  );
  const [userData, setUserData] = useState<UserDataType>(null);

  const updateUserType = (newUserType: string) => {
    setUserData({ ...userData, user_type: newUserType } as UserDataType);
  };

  const login = (data: UserDataType) => {
    localStorage.setItem("isLoggedIn", "true");
    setIsAuthenticated(true);
    setUserData(data);
  };

  const logout = () => {
    localStorage.removeItem("isLoggedIn");
    setIsAuthenticated(false);
    setUserData(null);
  };

  const { makeRequest } = useCustomAxios();

  // runs on first render if user logged in previously i.e. session cookie is still valid
  // so no login required in current session but user data is unavailable
  useEffect(() => {
    if (isAuthenticated && !userData) {
      makeRequest(
        [
          {
            url: SESSION_LOGIN_ENDPOINT,
            method: "get",
          },
        ],
        (responseDataArr) => {
          const [userData] = responseDataArr;
          setUserData(userData);
        },
        () => {
          setIsAuthenticated(false);
          localStorage.removeItem("isLoggedIn");
        }
      );
    }
  }, []);

  const contextValue = {
    isAuthenticated,
    userData,
    updateUserType,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
