import { MouseEvent, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { APIKeyType, PlanType } from "../../utils/general";
import { GET_PLAN_DETAILS, CREATE_KEY_ENDPOINT } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { AuthLayout } from "../../components/AuthLayout";
import { SubscriptionCard } from "../../components/SubscriptionCard";
import styles from "./SubscriptionPlans.module.css";

export const SubscriptionPlansPage = () => {
  const { makeRequest } = useCustomAxios();
  const navigate = useNavigate();
  const [plans, setPlans] = useState<PlanType[]>([]);

  // checkout channel created on page render rather than within handleSubscriptionCardActivate
  // coz checkout page may have rendered b4 handleSubscriptionCardActivate is fully processed
  const checkoutChannel = useRef(new BroadcastChannel("checkout"));
  const checkoutInitInfo = useRef({
    checkoutKey: {} as APIKeyType,
    checkoutPageIsLoaded: false,
  });
  // reqd to ensure handleCheckoutPageIsLoaded has access to the most recent checkout key value
  const [checkoutKeyStrValue, setCheckoutKeyStrValue] = useState("");

  const handleSubscriptionCardActivate = (e: MouseEvent<HTMLAnchorElement>) => {
    const [activatedPlan] = plans.filter(
      (plan) => plan.plan_id === e.currentTarget.id
    );
    const keyRequestData = {
      key_status: "inactive",
      scan_limit: activatedPlan.scan_limit,
      batch_size: activatedPlan.batch_size,
      plan_name: activatedPlan.plan_name,
    };
    makeRequest(
      [{ url: CREATE_KEY_ENDPOINT, method: "post", data: keyRequestData }],
      (responseDataArr) => {
        const [newKey] = responseDataArr;
        if (checkoutInitInfo.current.checkoutPageIsLoaded) {
          console.log("checkout page already loaded");
          checkoutChannel.current.postMessage({ key: newKey.key });
          checkoutChannel.current.close();
          navigate(`/dashboard/key-details`, {
            state: { key: newKey, isCheckoutSession: true },
          });
        } else {
          console.log("checkout page not loaded at key retrieval");
          checkoutInitInfo.current = {
            checkoutKey: newKey,
            checkoutPageIsLoaded: false,
          };
          setCheckoutKeyStrValue(newKey.key);
        }
      },
      (error) => console.error(error) // TODO: refactor error handling
    );
  };

  useEffect(() => {
    const handleCheckoutPageIsLoaded = (e: MessageEvent) => {
      if (e.data.msg !== "checkout page loaded") return;
      if (checkoutKeyStrValue) {
        console.log("key retrieved first");
        // checkout page loaded after key had been retrieved
        checkoutChannel.current.postMessage({ key: checkoutKeyStrValue });
        checkoutChannel.current.removeEventListener(
          "message",
          handleCheckoutPageIsLoaded
        );
        checkoutChannel.current.close();
        navigate(`/dashboard/key-details`, {
          state: {
            key: checkoutInitInfo.current.checkoutKey,
            isCheckoutSession: true,
          },
        });
      } else {
        console.log("checkout page loaded first");
        checkoutInitInfo.current = {
          checkoutKey: {} as APIKeyType,
          checkoutPageIsLoaded: true,
        };
      }
    };
    checkoutChannel.current.addEventListener(
      "message",
      handleCheckoutPageIsLoaded
    );

    return () => {
      checkoutChannel.current.removeEventListener(
        "message",
        handleCheckoutPageIsLoaded
      );
    };
  }, [checkoutKeyStrValue]);

  useEffect(() => {
    makeRequest(
      [
        {
          url: GET_PLAN_DETAILS,
          method: "get",
          params: {
            plan_type: "subscription",
          },
        },
      ],
      (responseDataArr) => {
        const [plansData] = responseDataArr;
        setPlans(plansData);
      },
      (error) => console.error(error) // TODO: refactor error handling
    );
  }, []);

  return (
    <AuthLayout pageH1="Plans">
      <div className={styles.main_container}>
        <div className={styles.content_container}>
          <h2 className={styles.content_heading}>Subscription Plans</h2>
          <p className={styles.instructions}>
            Start building for free, then add a site plan to go live. Account
            plans unlock additional features.
          </p>
          <div className={styles.plans}>
            {plans.map((plan) => {
              const [planInfo, ...planData] = plan.plan_description.split("\n");
              return (
                <SubscriptionCard
                  key={plan.plan_id}
                  id={plan.plan_id}
                  name={plan.plan_name}
                  info={planInfo}
                  price={+plan.plan_cost.$numberDecimal}
                  features={planData}
                  onActivate={handleSubscriptionCardActivate}
                />
              );
            })}
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
