import DocumentTitle from "react-document-title";
import React, { useEffect, useState, useRef, useId } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LOGIN_ENDPOINT } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { useAuth } from "../../context/AuthProvider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { TextField } from "../../components/TextField";
import { PasswordField } from "../../components/PasswordField";
import { SubmitButton } from "../../components/SubmitButton";
import ErrorMessages from "../../components/ErrorMessages/errorMessage";
import { ErrorModal } from "../../components/Modals/errorModal";
import logo from "../../assets/lera.png";
import styles from "./Login.module.css";

interface LoginDataType {
  email: string;
  password: string;
}

export const LoginPage = () => {
  const id = useId();
  const loginDataIds = useRef<LoginDataType>({
    email: `email-${id}`,
    password: `password-${id}`,
  });
  const [formData, setFormData] = useState<LoginDataType>({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({} as LoginDataType);
  const [formErrorsCount, setFormErrorsCount] = useState<number>(0);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const errorModalMsg = useRef<string>("");

  // records the number of login requests sent to server
  const loginAttempts = useRef<number>(0);

  const errorsRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  //TODO: refactor email validation, implement password strength validation
  const validateLoginData = () => {
    let validationErrors = {} as LoginDataType;
    let errorsCount = 0;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (formData.email === "") {
      validationErrors.email = "You must enter an email address";
      errorsCount++;
    } else if (!regex.test(formData.email)) {
      validationErrors.email = "You must enter a valid email address";
      errorsCount++;
    }

    if (formData.password === "") {
      validationErrors.password = "You must enter a password";
      errorsCount++;
    } else if (formData.password.length < 4) {
      // user is not made aware of this constraint
      validationErrors.password = "You must enter a valid password";
      errorsCount++;
    }
    return { errorsCount, validationErrors };
  };

  const { isLoading, makeRequest } = useCustomAxios();
  const { login } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname ?? "/";

  const handleServerError = (error: any) => {
    if (error.response) {
      switch (error.response.data.Error.toLowerCase()) {
        case "user not found":
          errorModalMsg.current =
            "No account found. Please Sign Up to create an account.";
          break;
        default:
          if (loginAttempts.current < 5) {
            errorModalMsg.current = "Email or Password is incorrect";
          } else {
            errorModalMsg.current =
              "Email or Password is incorrect. Please click Forgot Your Password link to reactivate your account.";
          }
          break;
      }
    } else {
      errorModalMsg.current =
        "You are offline or server is facing some technical issues. Please try again later.";
    }
    setShowErrorModal(true);
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { errorsCount, validationErrors } = validateLoginData();
    if (errorsCount > 0) {
      setFormErrors(validationErrors);
      setFormErrorsCount(errorsCount);
    } else {
      loginAttempts.current++;
      makeRequest(
        [
          {
            url: LOGIN_ENDPOINT,
            method: "post",
            data: formData,
          },
        ],
        (responseDataArr) => {
          const [userData] = responseDataArr;
          login(userData);
          navigate(from, { replace: true });
        },
        handleServerError
      );
    }
  };

  const handleErrorModalClose = () => {
    errorModalMsg.current = "";
    setShowErrorModal(false);
  };

  useEffect(() => {
    if (formErrorsCount === 0 && mainRef.current) {
      mainRef.current.focus();
    } else if (formErrorsCount > 0 && errorsRef.current) {
      errorsRef.current.focus();
    }
  }, [formErrorsCount]);

  return (
    <DocumentTitle title="Login - Lera cloud">
      <div className={styles.login_container}>
        <header>
          <Link to="/">
            <img src={logo} alt="Lera Homepage" className={styles.logo} />
          </Link>
        </header>
        <main>
          <div className={styles.login_intro}>
            <h1 className={styles.login_text} ref={mainRef} tabIndex={-1}>
              Log in to your account
            </h1>
            <p className={styles.login_redirect_wrapper}>
              Or{" "}
              <Link to="/signup" className={styles.login_redirect}>
                {" "}
                create your account today
              </Link>
            </p>
          </div>
          <div className={styles.login_box}>
            {formErrorsCount > 0 && (
              <ErrorMessages
                messages={formErrors}
                ids={loginDataIds.current}
                count={formErrorsCount}
                ref={errorsRef}
              />
            )}
            <form onSubmit={handleLogin} noValidate>
              <p>All fields are required</p>
              <TextField
                id={loginDataIds.current.email}
                label="Email Address"
                type="email"
                name="email"
                value={formData.email}
                setValue={handleInputChange}
                autoComplete="email"
                errMsg={formErrors.email ?? ""}
                stylesClass={styles.input_box}
              />
              <PasswordField
                id={loginDataIds.current.password}
                label="Password"
                name="password"
                value={formData.password}
                setValue={handleInputChange}
                autoComplete="current-password"
                toggleLabel="Show Password"
                errMsg={formErrors.password ?? ""}
                inputStyle={styles.input_box}
              />
              <div className={styles.password_auth}>
                <div className={styles.checkbox}>
                  <input type="checkbox" name="remember" id="remember" />
                  <label htmlFor="remember">Remember me</label>
                </div>
                <Link
                  to="/forgot-password"
                  className={styles.forgot_password_redirect}
                >
                  Forgot Your Password?
                </Link>
              </div>
              <SubmitButton label="Log In" disabled={isLoading} />
            </form>
          </div>
        </main>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
            <Typography role="alert">Loading</Typography>
          </Backdrop>
        )}
        {showErrorModal && (
          <ErrorModal
            message={errorModalMsg.current}
            open={showErrorModal}
            onClose={handleErrorModalClose}
          />
        )}
      </div>
    </DocumentTitle>
  );
};
