import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CREATE_KEY_ENDPOINT,
  GET_USER_KEYS_ENDPOINT,
} from "../../api/endpoints";
import axios from "axios";
import { errorToast } from "../../components/toast/customToast";
import { useCustomAxios } from "../../api/axios";
import { useAuth } from "../../context/AuthProvider";
import { APIKeyType } from "../../utils/general";
import { AuthLayout } from "../../components/AuthLayout";
import { Table } from "../../components/Table";
import { Icon } from "../../components/Icon";
import { getWelcomeText, getUserName } from "./DashboardText";
import { keysTableHeaders } from "./DashboardTableHeaders";
import styles from "./Dashboard.module.css";
import stylesPagination from "./Paginition.module.css";

// TODO: implement mobile component
export const DashboardPage = () => {
  const navigate = useNavigate();
  const { makeRequest } = useCustomAxios();
  const { userData, updateUserType } = useAuth();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [keys, setKeys] = useState<APIKeyType[]>([]);

  const renderKeysTableBody = () => {
    return (
      <tbody>
        {keys.map((apiKey) => {
          return (
            <tr key={apiKey.key}>
              <td>
                <Link to={`/dashboard/key-details`} state={{ key: apiKey }}>
                  {apiKey.key.slice(-11, -1)}
                </Link>
              </td>
              <td>{apiKey.key_name ?? ""}</td>
              <td>
                {new Date(
                  +apiKey.created_at.$date.$numberLong
                ).toLocaleDateString("en-US")}
              </td>
              <td>
                <strong data-status={apiKey.key_status.toLowerCase()}>
                  {apiKey.key_status[0].toUpperCase() +
                    apiKey.key_status.slice(1)}
                </strong>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  // TODO: refactor error handling
  const handleServerError = (error: any) => {
    console.error(error);
  };

  const getNewAPIKey = async () => {
    if (userData?.user_type === "new") {
      const keyRequestData = {
        key_status: "trial",
        scan_limit: 100,
        batch_size: 50,
        plan_name: "trial",
      };
      makeRequest(
        [{ url: CREATE_KEY_ENDPOINT, method: "post", data: keyRequestData }],
        (responseDataArr) => {
          const [newKey] = responseDataArr;
          updateUserType("trial");
          setKeys([newKey]);
        },
        handleServerError
      );
    } else {
      navigate("/subscription-plans");
    }
  };

  const fetchAPIKeys = (sortHeader: string, sortOrder: string) => {
    if (userData?.user_type !== "new") {
      // new user has no api key
      const sortParam =
        sortHeader === "Date Created" ? "created_at" : "key_status";
      const orderParam = sortOrder === "ascending" ? "asc" : "desc";
      makeRequest(
        [
          {
            url: GET_USER_KEYS_ENDPOINT,
            method: "get",
            params: {
              page: 1,
              limit: 10,
              sort_by: sortParam,
              order: orderParam,
            },
          },
        ],
        (responseDataArr) => {
          const [userKeysData] = responseDataArr;
          setKeys(userKeysData[0]);
        },
        handleServerError
      );
    }
  };

  const onHandlePageChange = (p: number) => {
    if (p > totalPages || p <= 0 || p === pageNumber) return;
    setPageNumber(p);
    async function getUserKeys() {
      try {
        let response = await axios.get(
          GET_USER_KEYS_ENDPOINT + `/?page=${p}&limit=10`,
          {
            withCredentials: true,
          }
        );
        console.log(response.data[0]);
        setKeys(response.data[0]);
      } catch (error: any) {
        errorToast(error.message);
      }
    }
    getUserKeys();
  };

  useEffect(() => {
    async function getUserKeys() {
      try {
        let response = await axios.get(
          GET_USER_KEYS_ENDPOINT + "/?page=1&limit=10",
          {
            withCredentials: true,
          }
        );

        const totalPages = response.data[1];
        setTotalPages(totalPages);
      } catch (error: any) {
        console.log(error.message);
      }
    }

    getUserKeys();
  }, []);

  const renderPageList = () => {
    const pageListLinks = [];
    const totalVisiblePages = 7;

    if (totalPages <= totalVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageListLinks.push(
          <li
            key={i}
            className={`${stylesPagination.pagination_item} ${
              pageNumber === i ? stylesPagination.active : ""
            }`}
          >
            <Link to="#" onClick={() => onHandlePageChange(i)}>
              {i}
            </Link>
          </li>
        );
      }
    } else {
      const middlePagesVisible = 3;
      const startPages = 2;
      const endPages = 2;

      const start = Math.max(2, pageNumber - 1);
      const end = Math.min(totalPages - 1, pageNumber + 1);

      pageListLinks.push(
        <li
          key={1}
          className={`${stylesPagination.pagination_item} ${
            pageNumber === 1 ? stylesPagination.active : ""
          }`}
        >
          <Link to="#" onClick={() => onHandlePageChange(1)}>
            1
          </Link>
        </li>
      );

      if (start > startPages + 1) {
        pageListLinks.push(
          <li key="start-ellipsis" className={stylesPagination.pagination_item}>
            <span>...</span>
          </li>
        );
      }

      for (let i = start; i <= end; i++) {
        pageListLinks.push(
          <li
            key={i}
            className={`${stylesPagination.pagination_item} ${
              pageNumber === i ? stylesPagination.active : ""
            }`}
          >
            <Link to="#" onClick={() => onHandlePageChange(i)}>
              {i}
            </Link>
          </li>
        );
      }

      if (end < totalPages - endPages) {
        pageListLinks.push(
          <li key="end-ellipsis" className={stylesPagination.pagination_item}>
            <span>...</span>
          </li>
        );
      }

      pageListLinks.push(
        <li
          key={totalPages}
          className={`${stylesPagination.pagination_item} ${
            pageNumber === totalPages ? stylesPagination.active : ""
          }`}
        >
          <Link to="#" onClick={() => onHandlePageChange(totalPages)}>
            {totalPages}
          </Link>
        </li>
      );
    }

    return pageListLinks;
  };

  return (
    <AuthLayout pageH1="Dashboard">
      <main className={styles.main_container}>
        <div className={styles.text_container}>
          <h2>
            {`Welcome, ${getUserName(
              userData?.first_name,
              userData?.last_name
            )}`}
          </h2>
          <p>{getWelcomeText(userData?.user_type)}</p>
          <button type="button" className="primary" onClick={getNewAPIKey}>
            <Icon name="Add" />
            <span>Create Key</span>
          </button>
        </div>
        <div className={styles.table_container}>
          <Table
            title="API Keys Overview"
            headers={keysTableHeaders}
            initialSortHeader="Date Created"
            fetchTableData={fetchAPIKeys}
            tableDataLength={keys.length}
            renderTableBody={renderKeysTableBody}
          />
          <nav aria-label="Pagination">
            <div className={stylesPagination.pagination_container}>
              <span className={stylesPagination.pagination_info}>
                Showing {pageNumber} of {totalPages} pages
              </span>
              <ul className={stylesPagination.pagination_list}>
                <li className={stylesPagination.pagination_item}>
                  <Link
                    to={"#"}
                    onClick={() => onHandlePageChange(pageNumber - 1)}
                    aria-label="Previous Page"
                  >
                    &lt;
                  </Link>
                </li>
                {renderPageList()}
                <li className={stylesPagination.pagination_item}>
                  <Link
                    to={"#"}
                    onClick={() => onHandlePageChange(pageNumber + 1)}
                    aria-label="Next Page"
                  >
                    &gt;
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </main>
    </AuthLayout>
  );
};
