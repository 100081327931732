import { useNavigate, Link } from "react-router-dom";
import { LOGOUT_ENDPOINT } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { useAuth } from "../../context/AuthProvider";
import logo from "../../assets/lera.png";
import styles from "./Logout.module.css";

export const LogoutPage = () => {
  const navigate = useNavigate();
  const { makeRequest } = useCustomAxios();
  const { logout } = useAuth();

  const handleServerError = (error: any) => console.error(error);

  const handleLogout = () => {
    makeRequest(
      [
        {
          url: LOGOUT_ENDPOINT,
          method: "get",
        },
      ],
      () => {
        logout();
        navigate("/", { replace: true });
      },
      handleServerError
    );
  };

  const handleCancel = () => {
    navigate(-1); 
  };

  return (
    <div className={styles.logout_container}>
      <header>
        <Link to="/">
          <img src={logo} alt="Lera" className={styles.logo} />
        </Link>
      </header>
      <main>
        <div className={styles.logout_intro}>
          <h1 className={styles.logout_text}>Log Out Confirmation</h1>
        </div>
        <div className={styles.logout_box}>
          <p>Are you sure you want to log out of your Lera account?</p>
          <div className={styles.button_container}>
            <button
              type="button"
              onClick={handleLogout}
              className="primary"
            >
              Log Out
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}
