import styles from "./ProfileForm.module.css"

const PasswordChangeForm = () => {
    return (
        <div className={styles.form_wrapper}>
            <form className={styles.change_details_form}>
                <div className={styles.form_field}>
                    <label htmlFor="oldpassword" className={styles.form_label}>Old Password</label><br />
                    <input type="password" className={styles.form_input} />                    
                </div>
                <div className={styles.form_field}>
                    <label htmlFor="newpassword" className={styles.form_label}>New Password</label><br />
                    <input type="password" className={styles.form_input} />
                </div>
                <div className={styles.button_container}>
                    <button className={styles.cancel_button}>Cancel</button>
                    <button className={styles.save_button}>Save Changes</button>
                </div>
            </form>
        </div>
    )
}

export default PasswordChangeForm