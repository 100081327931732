// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_global_container__5A8qo {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    background-color: #FAFAFA;
}

.Layout_main_container__ifhWa {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: #FAFAFA;
}

.Layout_child_container__OHJtK {
    height: 100%;
    background-color: #FAFAFA;
}

@media only screen and (max-width: 50rem) {
    .Layout_main_container__ifhWa {
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".global_container {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    justify-content: flex-start;\n    background-color: #FAFAFA;\n}\n\n.main_container {\n    width: 100vw;\n    height: 100vh;\n    overflow: auto;\n    background-color: #FAFAFA;\n}\n\n.child_container {\n    height: 100%;\n    background-color: #FAFAFA;\n}\n\n@media only screen and (max-width: 50rem) {\n    .main_container {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"global_container": `Layout_global_container__5A8qo`,
	"main_container": `Layout_main_container__ifhWa`,
	"child_container": `Layout_child_container__OHJtK`
};
export default ___CSS_LOADER_EXPORT___;
