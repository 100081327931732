// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg {
  width: var(--icon-size, 1.5rem);
  height: var(--icon-size, 1.5rem);
  fill: var(--icon-fill, none);
  stroke: var(--icon-stroke, currentColor);
  stroke-width: 1.5;
}

/* prevents tooltip display on hover */
svg[role="img"] {
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Icon/Icon.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,gCAAgC;EAChC,4BAA4B;EAC5B,wCAAwC;EACxC,iBAAiB;AACnB;;AAEA,sCAAsC;AACtC;EACE,oBAAoB;AACtB","sourcesContent":["svg {\n  width: var(--icon-size, 1.5rem);\n  height: var(--icon-size, 1.5rem);\n  fill: var(--icon-fill, none);\n  stroke: var(--icon-stroke, currentColor);\n  stroke-width: 1.5;\n}\n\n/* prevents tooltip display on hover */\nsvg[role=\"img\"] {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
