// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopupPlans_main_container__Eyjog {
  background: var(--backgroundSecondary);
  padding: 2rem 4% 0 0;
}

.TopupPlans_content_container__Nlrh4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  border-radius: 0.625rem;
}

.TopupPlans_content_container__Nlrh4 h1 {
  font-size: 2.4rem;
  font-weight: 600;
}

.TopupPlans_topup_form__cV\\+iz {
  display: contents;
}

.TopupPlans_topup_form__cV\\+iz p {
  font-size: 1rem;
}

.TopupPlans_topup_form__cV\\+iz label {
  font-weight: 600;
}

.TopupPlans_topup_form__cV\\+iz select {
  width: 100%;
  margin-top: 0.25rem;
  border-radius: 0.625rem;
  padding: 0.75rem;
  font-size: 0.875rem;
}

.TopupPlans_topup_form__cV\\+iz option {
  padding: 1rem 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/topup-plans/TopupPlans.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".main_container {\n  background: var(--backgroundSecondary);\n  padding: 2rem 4% 0 0;\n}\n\n.content_container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 1.25rem;\n  border-radius: 0.625rem;\n}\n\n.content_container h1 {\n  font-size: 2.4rem;\n  font-weight: 600;\n}\n\n.topup_form {\n  display: contents;\n}\n\n.topup_form p {\n  font-size: 1rem;\n}\n\n.topup_form label {\n  font-weight: 600;\n}\n\n.topup_form select {\n  width: 100%;\n  margin-top: 0.25rem;\n  border-radius: 0.625rem;\n  padding: 0.75rem;\n  font-size: 0.875rem;\n}\n\n.topup_form option {\n  padding: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_container": `TopupPlans_main_container__Eyjog`,
	"content_container": `TopupPlans_content_container__Nlrh4`,
	"topup_form": `TopupPlans_topup_form__cV+iz`
};
export default ___CSS_LOADER_EXPORT___;
