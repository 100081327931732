import { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { AuthLayout } from "../../components/AuthLayout";
import { Tab } from "../../components/Tab";
import { EditKeyDetail } from "./KeyDetailsEdit";
import { Breadcrumb } from "./KeyDetailsBreadcrumb";
import {
  ScanHistoryTable,
  TransactionDetailsTable,
} from "./KeyDetailsTabPanels";
import styles from "./KeyDetails.module.css";

export const KeyDetailsPage = () => {
  const { userData, updateUserType } = useAuth();
  const {
    state: { key, isCheckoutSession },
  } = useLocation();

  const checkoutChannel = useRef<BroadcastChannel | null>(null);
  const [checkoutInfo, setCheckoutInfo] = useState({ id: "", status: "" });
  // updates key status on checkout completion
  const [keyStatus, setKeyStatus] = useState(key.key_status);

  const breadcrumbLinks = [
    {
      label: "Dashboard",
      to: "/dashboard",
    },
    {
      label: key.key.slice(-11, -1),
      to: "/dashboard/key-details",
    },
  ];

  const apiDetailsTabList = [
    {
      header: "Scan History",
      content: <ScanHistoryTable apiKey={key.key} />,
    },
    {
      header: "Transaction Details",
      content: (
        <TransactionDetailsTable
          apiKey={key.key}
          checkoutStatus={checkoutInfo.status}
        />
      ),
    },
  ];

  useEffect(() => {
    if (isCheckoutSession) {
      const handleCheckout = (e: MessageEvent) => {
        switch (e.data.status) {
          case "checkout initiated":
            setCheckoutInfo(e.data);
            break;
          case "checkout completed":
            if (e.data.id !== checkoutInfo.id) return; // not the same checkout session
            if (userData?.user_type !== "lera") updateUserType("lera");
            setKeyStatus("active");
            setCheckoutInfo((prevInfo) => ({
              ...prevInfo,
              status: e.data.status,
            }));
            checkoutChannel.current?.removeEventListener(
              "message",
              handleCheckout
            );
            checkoutChannel.current?.close();
            break;
        }
      };

      checkoutChannel.current = new BroadcastChannel("checkout");
      checkoutChannel.current.addEventListener("message", handleCheckout);

      return () => {
        // if txn is taking too long to complete and user exits page
        checkoutChannel.current?.removeEventListener("message", handleCheckout);
        checkoutChannel.current?.close();
      };
    }
  }, [checkoutInfo.id]); // ensures handleCheckout has access to the most recent checkout id value

  return (
    <AuthLayout pageH1="Key Details">
      <Breadcrumb Links={breadcrumbLinks} stylesClass={styles.breadcrumb} />
      <main className={styles.main_container}>
        <div className={styles.heading}>
          <h2>{key.key}</h2>
          <strong data-status={keyStatus}>
            <span className="visually-hidden">Status:</span>
            {keyStatus[0].toUpperCase() + keyStatus.slice(1)}
          </strong>
        </div>
        <div className={styles.details_container}>
          <div className={styles.key_details}>
            {["callback_url", "key_name"].map((detail, index) => {
              return (
                <EditKeyDetail
                  key={index}
                  apiKey={key.key}
                  detailLabel={detail}
                  detailValue={key[detail] ?? ""}
                  EditCtrlStylesClass={styles.edit}
                  formStylesClass={styles.edit_form}
                  textFieldStylesClass={styles.text_field}
                />
              );
            })}
            <button
              type="button"
              className={`button secondary ${styles.revoke_key}`}
            >
              Revoke Key
            </button>
          </div>
          <div className={styles.plan_details}>
            <p>
              Plan: {key.plan_name[0].toUpperCase() + key.plan_name.slice(1)}
            </p>
            <p>
              Billing Date:{" "}
              {`${new Date(
                +key.created_at.$date.$numberLong
              ).toLocaleDateString("en-US")} - ${new Date(
                +key.expiration_date.$date.$numberLong
              ).toLocaleDateString("en-US")}`}
            </p>
            <p>Scan Limit: {key.scan_limit}</p>
            <Link
              to="/dashboard/topup-plans"
              className={`button primary ${styles.topup_link}`}
            >
              Add Credits
            </Link>
          </div>
        </div>
        <div className={styles.tab_container}>
          <Tab
            tabList={apiDetailsTabList}
            {...(isCheckoutSession && {
              initialSelectedTab: "Transaction Details",
            })}
          />
        </div>
      </main>
    </AuthLayout>
  );
};
