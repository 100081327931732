import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useCustomAxios } from "../../api/axios";
import { CREATE_CHECKOUT_SESSION_ENDPOINT } from "../../api/endpoints";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export const CheckoutPage = () => {
  const { plan } = useParams();
  const { makeRequest } = useCustomAxios();
  const checkoutChannel = useRef(new BroadcastChannel("checkout"));

  useEffect(() => {
    // ensures key is only sent to this page after it has been rendered
    checkoutChannel.current.postMessage({ msg: "checkout page loaded" });
    const handleCheckout = (e: MessageEvent) => {
      const { key } = e.data;
      if (!key) return;
      makeRequest(
        [
          {
            url: CREATE_CHECKOUT_SESSION_ENDPOINT,
            method: "post",
            data: {
              plan_name: plan,
              api_key: key,
            },
          },
        ],
        (responseDataArr) => {
          const [checkoutData] = responseDataArr;
          const { id, url } = checkoutData;
          checkoutChannel.current.postMessage({
            id: id,
            status: "checkout initiated",
          });
          checkoutChannel.current.removeEventListener(
            "message",
            handleCheckout
          );
          checkoutChannel.current.close();
          window.location.href = url;
        },
        (error) => console.error(error) // TODO: refactor error handling
      );
    };
    checkoutChannel.current.addEventListener("message", handleCheckout);
  }, []);

  return (
    // TODO: refactor to re-useable HTML component
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <CircularProgress color="inherit" />
      <Typography role="alert">Loading</Typography>
    </div>
  );
};
