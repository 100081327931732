// File: ErrorMessages.tsx
import styles from "../../stylesheets/ErrorMessages.module.css";
import React, { forwardRef } from "react";
import { ErrorListProps } from "./ErrorListProps";

const ErrorMessages = forwardRef<HTMLDivElement, ErrorListProps>((ErrorListProps, ref) => {
      return (
      <div className={styles.error_list} role="alert" tabIndex={-1} ref={ref}>
        <p>There were {ErrorListProps.count} errors in your form submission. Please fix the following</p>
        <ul>
          {Object.keys(ErrorListProps.messages).map((key) => {
            if (ErrorListProps.messages[key]) {
              return (
                <li className={styles.formErrors} key={key}>
                  <a href={`#${ErrorListProps.ids[key]}`}>{key} : {ErrorListProps.messages[key]}</a>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </div>
    );
  });

export default ErrorMessages;
