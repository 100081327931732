import { useState } from "react"
import Layout from "../../components/Layout/Layout"
import ProfileDetailForm from "../../components/ProfileForms/DetailChange"
import PasswordChangeForm from "../../components/ProfileForms/PasswordChange"
import styles from "./Profile.module.css"

const Profile = () => {
    const [option, setOption] = useState<Boolean>(true)

    const renderTable = () => {
        if (option)
            return <ProfileDetailForm />
        return <PasswordChangeForm />
    }

    return (
        <Layout>
            <main className={styles.profile_container}>
                <div className={styles.frame}>
                    <div className={styles.options_wrapper}>
                        <span className={`${styles.option_span} ${(option) ? styles.active_option : styles.dim_option}`} onClick={() => { setOption(true) }}>General</span>
                        <span className={`${styles.option_span} ${(!option) ? styles.active_option : styles.dim_option}`} onClick={() => { setOption(false) }}>Change Password</span>
                    </div>
                    {
                        renderTable()
                    }
                </div>
            </main>
         </Layout>
    )
}

export default Profile