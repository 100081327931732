import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../context/AuthProvider";
import { Icon } from "../Icon";
import { SidebarElement } from "../../utils/general";
import { SidebarData } from "../Sidebar/SidebarData";
import logo from "../../assets/lera.png";
import styles from "./Topbar.module.css";

// TODO: header landmark? - implement skip links and mobile nav
export const Topbar = ({ pageH1 }: { pageH1: string }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { userData } = useAuth();

  const toggleDropDown = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.topbar_container}>
      <div className={styles.lera_logo}>
        <img src={logo} alt="lera" className={styles.logo_image} />
      </div>
      {pageH1 && (
        <div className={styles.heading}>
          <h1>{pageH1}</h1>
        </div>
      )}
      <button
        type="button"
        className={styles.notification_icon}
        onClick={() => {}}
      >
        <Icon name={`Bell`} />
      </button>
      <Link to="" className={styles.profile_icon}>
        {`${userData?.first_name[0].toUpperCase()}${userData?.last_name[0].toUpperCase()}`}
      </Link>
      <div className={styles.dropdown}>
        <button
          type="button"
          onClick={toggleDropDown}
          className={styles.hamburger}
        >
          <Icon name={`Hamburger`} />
          <span className="visually-hidden">Menu</span>
        </button>
        <nav
          className={`${styles.dropdown_list} ${
            open ? styles.dropdown_list_active : ""
          }`}
        >
          <ul>
            {SidebarData.map((item: SidebarElement, index: number) => {
              return (
                <li key={index} className={`${styles.dropdown_list_item}`}>
                  <Link to={item.path} className={styles.item}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};
