import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import { SidebarElement } from "../../utils/general";
import { IconType, Icon } from "../Icon";
import logo from "../../assets/lera.png";
import styles from "./Sidebar.module.css";

export const Sidebar = () => {
  const location = useLocation();

  return (
    <div className={styles.sidebar_container}>
      <div className={styles.logo_container}>
        <div>
          <img src={logo} alt="Lera" id={styles.logo_image} />
        </div>
      </div>
      <nav className={styles.items_container}>
        <ul className={styles.items_list}>
          {SidebarData.map((item: SidebarElement, index: number) => (
            <li
              key={index}
              className={`${styles.item} ${
                item.path === location.pathname ? styles.activeItem : ""
              }`}
            >
              {/* review current/active page styling; doesn't meet WCAG requirements */}
              <Link to={item.path} className={styles.item_link}>
                <Icon name={item.title.replaceAll(" ", "") as IconType} />
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
