import axios from "axios";
import { useRef, useState, useEffect, useCallback } from "react";
import { noop } from "../utils/general";

const customAxios = axios.create({
  withCredentials: true,
});

interface QueryParams {
  url: string;
  method: string;
  data?: {};
  headers?: {};
  params?: {};
}

export const useCustomAxios = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const controllerArr = useRef<AbortController[]>([]);

  const makeRequest = useCallback((
    arr: QueryParams[],
    onSuccess: typeof noop,
    onError: typeof noop
  ) => {
    setIsLoading(true);
    Promise.all(arr.map((query) => runQuery(query)))
      .then((responseDataArr) => onSuccess(responseDataArr))
      .catch((err) => {
        // do nothing if query is aborted as component has unmounted
        if (!axios.isCancel(err)) {
          onError(err);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  const runQuery = async ({
    url,
    method,
    data = {},
    headers = {},
    params = {},
  }: QueryParams) => {
    const requestController = new AbortController();
    controllerArr.current.push(requestController);
    const response = await customAxios({
      url,
      method,
      data,
      headers,
      params,
      signal: requestController.signal,
    });
    return response.data;
  };

  useEffect(
    () => () => {
      controllerArr.current.forEach((controller) => controller.abort());
    },
    []
  );

  return { isLoading, makeRequest };
};
