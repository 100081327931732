// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorMessages_error_list__cqr1A {
    width: 95%;
    margin: 0 0 2rem 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  
  .ErrorMessages_formErrors__dsAZ- {
    width: 88%;
    padding-top: 0.8rem;
  }
  
  .ErrorMessages_error__3ytsZ {
    padding: 4px 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #A12F2B;
  }`, "",{"version":3,"sources":["webpack://./src/stylesheets/ErrorMessages.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,4CAA4C;EAC9C;;EAEA;IACE,UAAU;IACV,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,4CAA4C;IAC5C,eAAe;IACf,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":[".error_list {\n    width: 95%;\n    margin: 0 0 2rem 0.8rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-size: 14px;\n    font-family: 'Plus Jakarta Sans', sans-serif;\n  }\n  \n  .formErrors {\n    width: 88%;\n    padding-top: 0.8rem;\n  }\n  \n  .error {\n    padding: 4px 0;\n    font-family: 'Plus Jakarta Sans', sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    color: #A12F2B;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error_list": `ErrorMessages_error_list__cqr1A`,
	"formErrors": `ErrorMessages_formErrors__dsAZ-`,
	"error": `ErrorMessages_error__3ytsZ`
};
export default ___CSS_LOADER_EXPORT___;
