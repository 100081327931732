import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCustomAxios } from "../../api/axios";
import { CHECKOUT_SUCCESS_ENDPOINT } from "../../api/endpoints";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export const CheckoutSuccessPage = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const { makeRequest } = useCustomAxios();

  useEffect(() => {
    makeRequest(
      [
        {
          url: CHECKOUT_SUCCESS_ENDPOINT,
          method: "get",
          params: {
            session_id: sessionId,
          },
        },
      ],
      (responseDataArr) => {
        const checkoutChannel = new BroadcastChannel("checkout");
        checkoutChannel.postMessage({
          id: sessionId,
          status: "checkout completed",
        });
        checkoutChannel.close();
        const [invoiceData] = responseDataArr;
        window.location.href = invoiceData.invoice_url;
      },
      (error) => console.error(error) // TODO: refactor error handling
    );
  }, []);

  return (
    // TODO: refactor to re-useable HTML component
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <CircularProgress color="inherit" />
      <Typography role="alert">Loading</Typography>
    </div>
  );
};
