import { forwardRef, Ref } from "react";

interface TextFieldProps {
  id: string;
  label: string;
  type?: string; // type defaults to text
  name: string;
  value: string;
  setValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  errMsg?: string;
  stylesClass: string;
}

export const TextField = forwardRef(
  (
    {
      id,
      label,
      type = "text",
      name,
      value,
      setValue,
      autoComplete = "off",
      errMsg = "",
      stylesClass,
    }: TextFieldProps,
    ref: Ref<HTMLInputElement> | null
  ) => (
    <div className={stylesClass}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={setValue}
        aria-describedby={errMsg ? `err-${id}` : ""}
        autoComplete={autoComplete}
        aria-invalid={errMsg !== ""}
        ref={ref}
      />
      {errMsg && <span id={`err-${id}`}>{errMsg}</span>}
    </div>
  )
);
