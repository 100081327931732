import { Navigate, useLocation } from "react-router-dom";
import { NotFoundPage } from "../pages/not-found";

export const NotFoundRoute = () => {
  const location = useLocation();
  // if an unauthenticated user tries to access the authenticated routes
  // e.g., after session cookie expiration, re-direct to login page
  return ["/dashboard", "/subscription-plans"].includes(location.pathname) ? (
    <Navigate to="/login" state={{ from: location }} replace={true} />
  ) : (
    <NotFoundPage />
  );
};
