import { ReactNode } from "react";
import { useAuth } from "../../context/AuthProvider";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Topbar } from "../Topbar";
import { Sidebar } from "../Sidebar";
import styles from "./AuthLayout.module.css";

interface AuthLayoutProps {
  pageH1?: string;
  children: ReactNode;
}

// TODO: refactor sub-components and styling
export const AuthLayout = ({ pageH1 = "", children }: AuthLayoutProps) => {
  const { userData } = useAuth();

  return userData ? (
    <div className={styles.global_container}>
      <Sidebar />
      <div className={styles.main_container}>
        <Topbar pageH1={pageH1} />
        {children}
      </div>
    </div>
  ) : (
    // TODO: refactor to re-useable HTML component
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <CircularProgress color="inherit" />
      <Typography role="alert">Loading</Typography>
    </div>
  );
};
