// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthLayout_global_container__LEJRQ {
  min-height: 100vh;
  display: flex;
  gap: 3.5%;
  background-color: var(--backgroundPrimary);
}

.AuthLayout_main_container__nes0q {
  flex: 1 1;
}

@media only screen and (max-width: 800px) {
  .AuthLayout_main_container__nes0q {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AuthLayout/AuthLayout.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,SAAS;EACT,0CAA0C;AAC5C;;AAEA;EACE,SAAO;AACT;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".global_container {\n  min-height: 100vh;\n  display: flex;\n  gap: 3.5%;\n  background-color: var(--backgroundPrimary);\n}\n\n.main_container {\n  flex: 1;\n}\n\n@media only screen and (max-width: 800px) {\n  .main_container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"global_container": `AuthLayout_global_container__LEJRQ`,
	"main_container": `AuthLayout_main_container__nes0q`
};
export default ___CSS_LOADER_EXPORT___;
