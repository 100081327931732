import {
  ReactNode,
  KeyboardEvent,
  MouseEvent,
  useId,
  useState,
  useRef,
} from "react";
import "./Tab.module.css";

interface TabProps {
  tabList: {
    header: string;
    content: ReactNode;
  }[];
  initialSelectedTab?: string;
}

export const Tab = ({
  tabList,
  initialSelectedTab = tabList[0].header,
}: TabProps) => {
  const id = useId();
  const [selectedTab, setSelectedTab] = useState<string>(initialSelectedTab);
  const tab2FocusOnArrowNavigate = useRef<string | null>(null);

  const handleArrowNavigate = (
    e: KeyboardEvent<HTMLButtonElement>,
    index: number
  ) => {
    const lastTabIndex = tabList.length - 1;
    let tab2Focus;
    switch (e.key) {
      case "ArrowRight":
        tab2Focus =
          index === lastTabIndex
            ? tabList[0].header
            : tabList[index + 1].header;
        break;
      case "ArrowLeft":
        tab2Focus =
          index === 0
            ? tabList[lastTabIndex].header
            : tabList[index - 1].header;
        break;
    }
    if (tab2Focus) {
      e.stopPropagation();
      e.preventDefault();
      tab2FocusOnArrowNavigate.current = tab2Focus;
      setSelectedTab(tab2Focus);
    }
  };

  const handleTabClick = (e: MouseEvent<HTMLButtonElement>) => {
    setSelectedTab(e.currentTarget.id.split("-")[1]);
  };

  return (
    <section>
      <div role="tablist">
        {tabList.map((tab, index) => {
          return (
            <button
              key={tab.header}
              id={`${id}-${tab.header}`}
              type="button"
              role="tab"
              {...(tab.header !== selectedTab && { tabIndex: -1 })}
              aria-selected={tab.header === selectedTab}
              onKeyDown={(e) => handleArrowNavigate(e, index)}
              onClick={handleTabClick}
              ref={
                tab.header === tab2FocusOnArrowNavigate.current
                  ? (node) => {
                      if (node) {
                        tab2FocusOnArrowNavigate.current = null; // reset
                        node.focus();
                      }
                    }
                  : undefined
              }
            >
              <span>{tab.header}</span>
            </button>
          );
        })}
      </div>
      <div
        role="tabpanel"
        tabIndex={0}
        aria-labelledby={`${id}-${selectedTab}`}
      >
        {tabList.filter((tab) => tab.header === selectedTab)[0].content}
      </div>
    </section>
  );
};
