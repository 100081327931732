import { useState } from "react";
import { Icon } from "../Icon";
import styles from "./PasswordField.module.css";

interface PasswordFieldProps {
  id: string;
  label: string;
  name: string;
  value: string;
  setValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete: string;
  toggleLabel: string;
  errMsg: string;
  inputStyle: any;
}

export const PasswordField = ({
  id,
  label,
  name,
  value,
  setValue,
  autoComplete,
  toggleLabel,
  errMsg,
  inputStyle,
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const togglePassword = () => {
    setShowPassword((prevShowPwdValue) => !prevShowPwdValue);
  };

  return (
    <div className={inputStyle}>
      <label htmlFor={id}>{label}</label>
      <div className={styles.password_box}>
        <input
          id={id}
          type={showPassword ? "text" : "password"}
          name={name}
          value={value}
          onChange={setValue}
          aria-describedby={`err-${id}`}
          autoComplete={autoComplete}
          aria-invalid={errMsg !== ""}
        />
        <button
          type="button"
          className={styles.password_toggle}
          onClick={togglePassword}
          aria-pressed={showPassword}
        >
          <span className="visually-hidden">{toggleLabel}</span>
          <Icon name={showPassword ? "Eye" : "EyeSlash"} />
        </button>
      </div>
      {errMsg && <span id={`err-${id}`}>{errMsg}</span>}
    </div>
  );
};
