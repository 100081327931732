import { Link } from "react-router-dom";
import { noop } from "../../utils/general";
import { Icon } from "../Icon";
import styles from "./SubscriptionCard.module.css";

interface SubscriptionCardProps {
  id: string;
  name: string;
  info: string;
  price: number;
  features: string[];
  onActivate: typeof noop;
}

export const SubscriptionCard = ({
  id,
  name,
  info,
  price,
  features,
  onActivate
}: SubscriptionCardProps) => {
  return (
    <div id={id} className={styles.card} onClick={onActivate}>
      <div className={styles.info_container}>
        <div>
          <h3 className={styles.info_heading}>{name}</h3>
          <p>{info}</p>
        </div>
        <p className={styles.pricing}>
          ${price}
          <span className="visually-hidden">per month</span>
          <span aria-hidden="true">/mo</span>
        </p>
        <Link
          to={`/checkout/${name.toLowerCase()}`}
          target="_blank"
          className={`button primary ${styles.plan_link}`}
        >
          {`Buy ${name}`}
          <span className="visually-hidden">opens in a new tab</span>
        </Link>
      </div>
      <div className={styles.features_container}>
        <h4 className={styles.features_heading}>What's included</h4>
        <ul className={styles.features_list}>
          {features.map((feature, index) => (
            <li className={styles.feature} key={index}>
              <Icon name="Check" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
