import styles from "./ProfileForm.module.css"

const ProfileDetailForm = () => {
    return (
        <div className={styles.form_wrapper}>
            <form className={styles.change_details_form}>
                <div className={styles.form_field}>
                    <label htmlFor="email" className={styles.form_label}>Email</label><br />
                    <input type="text" className={styles.form_input} />
                </div>
                <div className={styles.form_field}>
                    <label htmlFor="firstname" className={styles.form_label}>First Name</label><br />
                    <input type="text" className={styles.form_input} />
                </div>
                <div className={styles.form_field}>
                    <label htmlFor="lastname" className={styles.form_label}>Last Name</label><br />
                    <input type="text" className={styles.form_input} />
                </div>
                <div className={styles.button_container}>
                    <button className={styles.cancel_button}>Cancel</button>
                    <button className={styles.save_button}>Save Changes</button>
                </div>
            </form>
        </div>
    )
}

export default ProfileDetailForm