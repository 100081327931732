import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { unauthenticatedUserRoutes } from "./unauthenticatedUserRoutes";
import { authenticatedUserRoutes } from "./authenticatedUserRoutes";
import { accessRoutes } from "./accessRoutes";
import { NotFoundRoute } from "./NotFoundRoute";

export const Routes = () => {
  const { isAuthenticated } = useAuth();
  const router = createBrowserRouter([
    {
      errorElement: <NotFoundRoute />,
      children: [
        // only render relevant routes
        ...(isAuthenticated
          ? authenticatedUserRoutes
          : unauthenticatedUserRoutes),
        ...accessRoutes,
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
