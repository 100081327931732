import { useNavigate, Link } from "react-router-dom";
import { LOGOUT_ENDPOINT } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { useAuth } from "../../context/AuthProvider";
import logo from "../../assets/lera.png";

export const LogoutPage = () => {
  const navigate = useNavigate();
  const { makeRequest } = useCustomAxios();
  const { logout } = useAuth();

  //TODO: refactor logout error handling
  const handleServerError = (error: any) => console.error(error);

  const handleLogout = () => {
    makeRequest(
      [
        {
          url: LOGOUT_ENDPOINT,
          method: "get",
        },
      ],
      () => {
        logout();
        navigate("/", { replace: true });
      },
      handleServerError
    );
  };

  return (
    <div>
      <header>
        <Link to="/">
          <img src={logo} alt="Lera" />
        </Link>
      </header>
      <main>
        <div>
          <h1>Log Out Confirmation</h1>
        </div>
        <div>
          <p>Are you sure you want to log out of your Lera account?</p>
          <button type="button" onClick={handleLogout}>
            Log Out
          </button>
        </div>
      </main>
    </div>
  );
};
