import { LoginPage } from "../pages/login";
import { LogoutPage } from "../pages/logout";

// required for both authenticated and unauthenticated user routes
export const accessRoutes = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
];
