import styles from "./Layout.module.css"
import { ReactNode } from 'react'
import { AuthLayout } from "../AuthLayout"

interface GlobalLayoutProps {
    children: ReactNode
}

const Layout = ({ children }: GlobalLayoutProps) => {
    return (
        <AuthLayout pageH1="Profile">
            <div className={styles.global_container}>
                
                <div className={styles.main_container}>
                    
                    <div className={styles.child_container}>
                        {children}
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}

export default Layout