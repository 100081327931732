import { SidebarElement } from "../../utils/general";

export const SidebarData: SidebarElement[] = [
  {
    title: "Dashboard",
    path: "/",
  },
  {
    title: "Plans",
    path: "/subscription-plans",
  },
  {
    title: "Docs",
    path: "",
  },
  {
    title: "Log Out",
    path: "/logout",
  },
];
