import { useEffect, useState } from "react";
import { PlanType } from "../../utils/general";
import { GET_PLAN_DETAILS } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { AuthLayout } from "../../components/AuthLayout";
import { SubmitButton } from "../../components/SubmitButton";
import styles from "./TopupPlans.module.css";

export const TopupPlansPage = () => {
  const [plans, setPlans] = useState<PlanType[]>([]);
  const { makeRequest } = useCustomAxios();

  // TODO: implement after Stripe integration
  const handleTopup = () => {};

  useEffect(() => {
    makeRequest(
      [
        {
          url: GET_PLAN_DETAILS,
          method: "get",
          params: {
            plan_type: "top-up",
          },
        },
      ],
      (responseDataArr) => {
        const [plansData] = responseDataArr;
        setPlans(plansData);
      },
      (error) => console.error(error) // TODO: refactor error handling
    );
  }, []);

  return (
    <AuthLayout>
      <main className={styles.main_container}>
        <div className={styles.content_container}>
          <h1>Pay as you go</h1>
          <form className={styles.topup_form} onSubmit={handleTopup} noValidate>
            <p>
              To pay online, please select the number of credits you wish to
              purchase:
            </p>
            <div>
              <label htmlFor="topup-options">Select Amount</label>
              <select id="topup-options">
                {plans.map((plan) => (
                  <option key={plan.plan_id} className={styles.dropdown_option}>
                    {`$${plan.plan_cost.$numberDecimal} for ${plan.scan_limit} scans`}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <SubmitButton label="Proceed To Payment" iconRight="ArrowRight" />
            </div>
          </form>
        </div>
      </main>
    </AuthLayout>
  );
};
