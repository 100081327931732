export const keysTableHeaders = [
  {
    label: "API Keys",
    sortBy: false,
  },
  {
    label: "Name",
    sortBy: false,
  },
  {
    label: "Date Created",
    sortBy: true,
  },
  {
    label: "Status",
    sortBy: true,
  },
];
