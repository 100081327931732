// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActivateAccount_wrapper__yxJH\\+ {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
}

.ActivateAccount_verified_image__WpgUZ {
  height: 100px;
  width: auto;
  margin: 5px;
}

.ActivateAccount_content_container__GyaZR {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #08dc6c;
  border-radius: 5px;
  margin-top: 300px;
}

.ActivateAccount_success_text__D0mzp {
  margin: 10px;
  font-weight: 400;
}

.ActivateAccount_redirect_text_container__MPsc2 {
  width: 70%;
  text-align: center;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ActivateAccount_redirect_text_container__MPsc2 > p {
  font-weight: 300;
}

.ActivateAccount_login__-P3wS {
  text-decoration: none;
  border: none;
  padding: 0.75rem 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/activate-account/ActivateAccount.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,wBAAmB;EAAnB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  width: 100vw;\n  height: 100vh;\n  align-items: center;\n}\n\n.verified_image {\n  height: 100px;\n  width: auto;\n  margin: 5px;\n}\n\n.content_container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  height: fit-content;\n  width: fit-content;\n  border: 1px solid #08dc6c;\n  border-radius: 5px;\n  margin-top: 300px;\n}\n\n.success_text {\n  margin: 10px;\n  font-weight: 400;\n}\n\n.redirect_text_container {\n  width: 70%;\n  text-align: center;\n  font-weight: 300;\n  margin-top: 20px;\n  margin-bottom: 10px;\n}\n\n.redirect_text_container > p {\n  font-weight: 300;\n}\n\n.login {\n  text-decoration: none;\n  border: none;\n  padding: 0.75rem 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ActivateAccount_wrapper__yxJH+`,
	"verified_image": `ActivateAccount_verified_image__WpgUZ`,
	"content_container": `ActivateAccount_content_container__GyaZR`,
	"success_text": `ActivateAccount_success_text__D0mzp`,
	"redirect_text_container": `ActivateAccount_redirect_text_container__MPsc2`,
	"login": `ActivateAccount_login__-P3wS`
};
export default ___CSS_LOADER_EXPORT___;
