import { Icon, IconType } from "../Icon";
import styles from "./SubmitButton.module.css";

interface ButtonProps {
  label?: string;
  disabled?: boolean;
  iconLeft?: string;
  iconRight?: string;
}

export const SubmitButton = ({
  label = "Submit",
  disabled = false,
  iconLeft = "",
  iconRight = "",
}: ButtonProps) => {
  return (
    <button
      type="submit"
      disabled={disabled}
      className={`primary button ${styles.submit_btn}`}
    >
      {iconLeft && <Icon name={iconLeft as IconType} />}
      <span>{label}</span>
      {iconRight && <Icon name={iconRight as IconType} />}
    </button>
  );
};
